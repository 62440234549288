<template>
	<div v-if="pageLoading">
		<div class="flex">
			<div style="flex: 1">
				<div class="card p-2">
					<Skeleton width="100%" height="6rem"></Skeleton>
				</div>
			</div>
			<div style="flex: 1">
				<div class="card mx-2 p-2">
					<Skeleton width="100%" height="6rem"></Skeleton>
				</div>
			</div>
			<div style="flex: 1">
				<div class="card p-2">
					<Skeleton width="100%" height="6rem"></Skeleton>
				</div>
			</div>
		</div>
		<div class="flex">
			<div style="flex: 1">
				<div class="card p-2">
					<Skeleton width="100%" height="6rem"></Skeleton>
				</div>
			</div>
			<div style="flex: 1">
				<div class="card mx-2 p-2">
					<Skeleton width="100%" height="6rem"></Skeleton>
				</div>
			</div>
			<div style="flex: 1">
				<div class="card p-2">
					<Skeleton width="100%" height="6rem"></Skeleton>
				</div>
			</div>
		</div>
		<div class="card p-2">
			<Skeleton width="100%" height="13rem"></Skeleton>
		</div>
		<div class="flex">
			<div style="flex: 1">
				<div class="card p-2">
					<Skeleton width="100%" height="10rem"></Skeleton>
				</div>
			</div>
			<div style="flex: 1">
				<div class="card ml-2 p-2">
					<Skeleton width="100%" height="10rem"></Skeleton>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="grid">
		<div class="col-12 md:col-3" v-if="earnings && user.role == 'owner'">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Total Amounts Cash</span>
						<div class="text-900 font-medium text-xl">{{ earnings.cashAmount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-wallet text-blue-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 md:col-3" v-if="earnings && user.role == 'owner'">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Transections Cash</span>
						<div class="text-900 font-medium text-xl">{{ earnings.cashCount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-credit-card text-blue-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 md:col-3" v-if="earnings && user.role == 'owner'">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Total Amounts Online</span>
						<div class="text-900 font-medium text-xl">{{ earnings.onlineAmount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-wallet text-blue-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 md:col-3" v-if="earnings && user.role == 'owner'">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Transections Online</span>
						<div class="text-900 font-medium text-xl">{{ earnings.onlineCount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-credit-card text-blue-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 md:col-4" v-if="statsForToday">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Tour Starts</span>
						<div class="text-900 font-medium text-xl">{{statsForToday.starts}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-send text-orange-500 text-xl"></i>
					</div>
				</div>
				<span class="text-500">tour starts today</span>
			</div>
		</div>
		<div class="col-12 md:col-4" v-if="statsForToday">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Running Tours</span>
						<div class="text-900 font-medium text-xl">{{statsForToday.running}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-clock text-orange-500 text-xl"></i>
					</div>
				</div>
				<span class="text-500">is running today</span>
			</div>
		</div>
		<div class="col-12 md:col-4" v-if="statsForToday">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Tour Ends</span>
						<div class="text-900 font-medium text-xl">{{statsForToday.ends}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-flag text-orange-500 text-xl"></i>
					</div>
				</div>
				<span class="text-500">tour ends today</span>
			</div>
		</div>

		<div class="col-12" v-if="monthlyBookings">
			<div class="card">
				<h5>Monthly Bookings</h5>
				<Chart type="bar" :data="monthlyBookings" :options="lineOptions" />
			</div>
		</div>

		<div class="col-12 md:col-6">
			<div class="card" v-if="mostToursToPlaces">
				<h5>Most Tours To Places</h5>
				<Chart type="pie" :data="mostToursToPlaces" :options="lightOptions"/>
				<div class="flex justify-content-center flex-wrap mt-2">
					<div class="flex align-items-center justify-content-center" v-for="(item, index) of mostToursToPlaces.labels" :key="index" style="float: left">
						<Tag v-if="pageLoading == false" class="m-1" :style="{'background-color': mostToursToPlaces.datasets[0].backgroundColor[index]}">{{item}}</Tag>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6">
			<div class="card" v-if="toursByManagers">
				<div>
					<h5>Tours By Managers</h5>
					<div><Chart type="pie" :data="toursByManagers" :options="lightOptions"/></div>
					<div class="flex justify-content-center flex-wrap mt-2">
						<div class="flex align-items-center justify-content-center" v-for="(item, index) of toursByManagers.labels" :key="index" style="float: left;">
							<Tag  class="m-1" :style="{'background-color': toursByManagers.datasets[0].backgroundColor[index]}">{{item}}</Tag>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/reactivity';
import Axios from 'axios';
import store from '../vuex';

export default {
	setup() {
		const statsForToday = ref();
		const mostToursToPlaces = ref();
		const monthlyBookings = ref();
		const toursByManagers = ref();
		const earnings = ref();
		const pageLoading = ref(true);

		var userData = store.getters.userData;
		var user = JSON.parse(userData);
		
		const lightOptions = ref({
			plugins: {
				legend: {
					display: false,
					position: 'bottom',
                    labels: {
                        color: '#495057',
                    }
                }
            }
        });

		const lineOptions = ref({
                plugins: {
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            });

		const getStatsForToday = () =>{
			Axios
			.get("api/operator/dashboard/stats-for-today")
			.then((res) =>{
				if (res.data.response == "success") {
					pageLoading.value = false;
					statsForToday.value = res.data.data;
				}
				else{
					pageLoading.value = true;
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getStatsForToday();
		
		const getMostToursToPlaces = () =>{
			Axios
			.get("api/operator/dashboard/most-tours-to-places")
			.then((res) =>{
				if (res.data.response == "success") {
					pageLoading.value = false;
					mostToursToPlaces.value = res.data.data;
				}
				else{
					pageLoading.value = true;
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getMostToursToPlaces();
		
		const getMonthlyBookings = () =>{
			Axios
			.get("api/operator/dashboard/monthly-bookings")
			.then((res) =>{
				if (res.data.response == "success") {
					pageLoading.value = false;
					monthlyBookings.value = res.data.data;
				}
				else{
					pageLoading.value = true;
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getMonthlyBookings();
		
		const getToursByManagers = () =>{
			Axios
			.get("api/operator/dashboard/tours-by-managers")
			.then((res) =>{
				if (res.data.response == "success") {
					toursByManagers.value = res.data.data;
				}
				else{
					pageLoading.value = true;
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getToursByManagers();

		const getEarnings = () =>{
			Axios
			.get("api/operator/dashboard/earnings")
			.then((res) =>{
				if (res.data.response == "success") {
					earnings.value = res.data.data;
					console.log(res.data.data);
				}
				else{
					pageLoading.value = true;
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getEarnings();

		return {
			pageLoading,
			statsForToday,
			mostToursToPlaces,
			monthlyBookings,
			toursByManagers,
			earnings,
			lightOptions,
			lineOptions,
			user
		}
	}
}
</script>